import { forwardRef } from "react";
import {
  AppBar,
  Button,
  Box,
  Dialog,
  Grid,
  Slide,
  Typography,
  Toolbar,
} from "@mui/material";
import { FormFieldText } from "../FormFieldText";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function InformationSlide(props) {
  const { assetInfo = {}, open, setOpen } = props;
  const { asset = {} } = assetInfo;
  const { assets = [], tag = "" } = asset;
  const theAssetTheDeviceIsAttachedToo = assets[0] || {};
  const { properties = "" } = theAssetTheDeviceIsAttachedToo;
  const parsedProperties = properties ? JSON.parse(properties) : {};
  const { invUOM, description } = parsedProperties;
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      fullScreen
      onClose={handleClose}
      open={open}
      TransitionComponent={Transition}
    >
      {/* Title */}
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <Typography variant="h5">{tag}</Typography>
        </Toolbar>
      </AppBar>

      {/* Asset Information */}
      <Grid
        container
        sx={{ height: "100%", padding: "0 1rem", paddingTop: "1rem" }}
      >
        <Grid item xs={12}>
          {/* Item Number */}
          <FormFieldText label="Item Number" value="" disabled={true} />

          {/* Unit of Measure */}
          <FormFieldText label="Unit of Measure" value={invUOM} disabled={true} />

          {/* Description */}
          <FormFieldText
            label="Description"
            value={description}
            disabled={true}
          />
        </Grid>
      </Grid>

      {/* Close Button */}
      <Box marginBottom="2rem" padding="0 1rem">
        <Button
          fullWidth
          onClick={() => {
            handleClose();
          }}
          variant="contained"
        >
          Close
        </Button>
      </Box>
    </Dialog>
  );
}
