import { atlasApi } from "../../services/atlas/atlasApi";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  hasAuth: true,
  hasBOL: false,
  hasManifest: false,
  hasQuantityAssets: false,
  token: null,
  user: {},
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    assignAuth: (state, data) => {
      console.log("***** assignAuth *****");
      state.hasAuth = data.payload;
    },
    // This is what is below. With time constraints I had to copy the code. Sure there is a better way of doing this
    assignAttributes: (state,data)=>{
      const {
        actions,
        forms,
        organization = {},
        privacyPolicy,
        termsOfUse,
        token,
        user,
      } = data.payload;
      // Grabbing the user type
      const { roles = [] } = user;
      const userMobileRoleType = roles[1];

      // Grabbing their available actions
      const { rolesMap = {} } = organization;
      const { mobile = {} } = rolesMap;
      const { actions: userAvailableActions = {} } =
        mobile[userMobileRoleType];

      state.user = user;
      state.userMobileRole = userMobileRoleType;
      state.userAvailableActions = userAvailableActions;
      state.token = token;
      state.forms = forms;
      state.actions = actions;
      state.organization = organization;
      state.termsOfUse = termsOfUse;
      state.privacyPolicy = privacyPolicy;
      if (actions) {
        Object.values(actions).forEach((val) => {
          if (val["usesQuantityAssets"]) state.hasQuantityAssets = true;
          if (val["usesManifest"]) state.hasManifest = true;
          if (val["usesBOL"]) state.hasBOL = true;
        });
      }
    },
    assignToken: (state,token) => {
      state.token = token.payload
    },
    logout: (state) => {
      console.log("***** logout *****");
      state.hasAuth = false;
      state.token = null;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      atlasApi.endpoints.auth.matchFulfilled,
      (state, { payload }) => {
        const {
          actions,
          forms,
          organization = {},
          privacyPolicy,
          termsOfUse,
          token,
          user,
        } = payload;
        // Grabbing the user type
        const { roles = [] } = user;
        const userMobileRoleType = roles[1];

        // Grabbing their available actions
        const { rolesMap = {} } = organization;
        const { mobile = {} } = rolesMap;
        const { actions: userAvailableActions = {} } =
          mobile[userMobileRoleType];

        console.log("***** assignAuth *****");

        state.user = user;
        state.userMobileRole = userMobileRoleType;
        state.userAvailableActions = userAvailableActions;
        state.token = token;
        state.forms = forms;
        state.actions = actions;
        state.organization = organization;
        state.termsOfUse = termsOfUse;
        state.privacyPolicy = privacyPolicy;
        if (actions) {
          Object.values(actions).forEach((val) => {
            if (val["usesQuantityAssets"]) state.hasQuantityAssets = true;
            if (val["usesManifest"]) state.hasManifest = true;
            if (val["usesBOL"]) state.hasBOL = true;
          });
        }
      }
    );
  },
});

export const selectUserMobileRole = (state) => state.user.userMobileRole;
export const selectUserAvailableActions = (state) =>
  state.auth.userAvailableActions;

export const selectToken = (state) => state.auth.token;
export const selectUser = (state) => state.auth.user;
export const selectForms = (state) => state.auth.forms;
export const selectActions = (state) => state.auth.actions;
export const selectOrganization = (state) => state.auth.organization;

export const selectHasAuth = (state) => state.auth.hasAuth;
export const selectHasQuantityAssets = (state) => state.auth.hasQuantityAssets;
export const selectHasManifest = (state) => state.auth.hasManifest;
export const selectHasBOL = (state) => state.auth.hasBOL;

export const { assignAuth, assignAttributes, assignToken, logout } = authSlice.actions;

export default authSlice.reducer;
