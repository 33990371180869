import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  cameraOpen: false,
  cameraOpenResult: null,
};

export const cameraSlice = createSlice({
  name: "camera",
  initialState,
  reducers: {
    openCamera: (state, open) => {
      state.cameraOpen = open.payload;
    },
    openCameraWithResult: (state, open) => {
      state.cameraOpen = true;
      state.cameraOpenResult = open.payload.handleCameraResult;
    },
  },
});

export const selectCameraOpen = (state) => state.camera.cameraOpen;

export const { openCamera, openCameraWithResult } = cameraSlice.actions;

export default cameraSlice.reducer;
