import { useState } from "react";
import { getAsset } from "../../../utils/api";
import { Grid, TextField, Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import InformationSlide from "./InformationSlide";

export default function AssetIdTextField(props) {
  const {
    assetInfo,
    formFields,
    label,
    multiline = false,
    onChange,
    onClear,
    readOnly = false,
    setAssetInfo,
    type = "text",
    token,
    value = "",
  } = props;
  const [open, setOpen] = useState(false);
  const { asset = {} } = assetInfo;
  const { tag = null } = asset;

  const handleClickOpen = () => {
    setOpen(true);
  };

  function extrapolateAssetId(event) {
    const string = event.target.value;
    const regex = /(?<=\/)(.*?)(?=\/)/gm;
    let extrapolatedAssetId = "";
    let m;
    
    // An UUID from LX is atleast 36 characters. But this will be a scan on a device that
    // will give us a string that looks like...
    // https://lxs.locatorx.com/1fdf5e95-6c0c-4e96-9abf-26041e8d0c7a/?hash=1f544a

    // Taken from https://regex101.com/codegen?language=javascript
    while ((m = regex.exec(string)) !== null) {
      // This is necessary to avoid infinite loops with zero-width matches
      if (m.index === regex.lastIndex) {
        regex.lastIndex++;
      }

      // This is the index for the asset Id
      if (m.index === 25) {
        extrapolatedAssetId = m[0];
      }
    }

    // This only runs if we have a valid assetId
    if (extrapolatedAssetId.length > 0) {
      getAsset({ assetId: extrapolatedAssetId, token }).then((res) => {
        setAssetInfo(res);
        onChange({ target: { value: extrapolatedAssetId } });
      });
    } else {
      onChange(event);
    }
  }

  return (
    <Grid container>
      {/* Information Slide */}
      <InformationSlide
        assetInfo={assetInfo}
        formFields={formFields}
        open={open}
        setOpen={setOpen}
      />

      {/* Title */}
      <Grid item xs={12}>
        <Typography
          align="left"
          component="div"
          display="flex"
          fontSize="14px"
          variant="subtitle1"
        >
          {label}{" "}
          <InfoIcon
            color={tag?.length > 0 ? "inherit" : "disabled"}
            fontSize="small"
            sx={{
              cursor: "pointer",
              marginLeft: ".2rem",
            }}
            readOnly={true}
            onClick={() => {
              if (tag.length > 0) {
                handleClickOpen();
              } else {
                return;
              }
            }}
          />
        </Typography>
      </Grid>

      {/* Input */}
      <Grid item xs={12} sx={{ pb: 2 }}>
        {readOnly ? (
          <Typography variant="subtitle2">{value}</Typography>
        ) : (
          <TextField
            disabled={readOnly}
            id="outlined-basic"
            label=""
            inputProps={{ inputMode: "none" }}
            fullWidth
            multiline={multiline}
            onChange={(event) => {
              // If a device has already been detected, this will clear it out so the
              // user can enter in another tag
              if(tag !== null) {
                
                // We clear out the asset
                onClear()
              } else {
                extrapolateAssetId(event)
              }

            }}
            type={type}
            value={tag ? tag : value}
          />
        )}
      </Grid>
    </Grid>
  );
}
