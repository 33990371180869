export function findAssetId(raw) {
  let assetId = raw,
    hash = null;
  let needPath = true;
  try {
    const url = new URL(raw);
    if (url) {
      const params = url.searchParams;
      if (params) {
        const a = params.get("assetId");
        if (a && a.length > 16) {
          assetId = a;
          needPath = false;
        }
        const h = params.get("hash");
        if (h && h.length > 4) {
          hash = h;
        }
      }
      if (needPath) {
        const path = url.pathname;
        if (path) {
          const paths = path.split("/");
          if (paths.length >= 1) {
            paths.forEach(function (p) {
              if (p.length === 36) {
                assetId = p;
              }
            });
          }
        }
      }
    }
  } catch (e) {}
  return { assetId: assetId, hash: hash };
}
