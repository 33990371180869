import { Box, Grid, InputLabel, TextField } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { v4 as uuid } from 'uuid';

export function FormFieldText(props) {
  const {
    disabled = false,
    border = "outlined-basic",
    fieldIndex,
    fieldKey,
    handleFieldValue,
    label,
    multiline = false,
    onChange,
    onClear = () => {},
    readOnly = false,
    type = "text",
    value = "",
    variant="outlined"
  } = props;

  const handleChange = (event) => {
    handleFieldValue(fieldIndex, event.target.value);
  };

  const formattedLabel = label?.replace(/\s/g, '') + Math.floor( Math.random() * 100) || uuid();
  
  return (
    <Grid container>
      <Grid item xs={12}>
       <InputLabel htmlFor={formattedLabel} sx={{ color: "black" }}>{label}</InputLabel>
      </Grid>
      <Grid item xs={12} sx={{ pb: 2 }}>
        {readOnly ? (
          <TextField 
            disabled={readOnly} 
            fullWidth 
            id={formattedLabel}
            multiline={multiline} 
            value={value} 
            variant="filled" 
          />
        ) : (
          <TextField
            disabled={readOnly}
            id={formattedLabel}
            inputProps={{ inputMode: 'none' }}
            fullWidth
            multiline={multiline}
            onChange={onChange ? onChange : handleChange}
            type={type}
            value={value}
            variant={variant}
          />
        )}
      </Grid>
    </Grid>
  );
}
