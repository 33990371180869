import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box, Grid, MenuItem, Select, Typography } from "@mui/material";
import { selectUserAvailableActions } from "../../../features/auth/authSlice";
import { assignAction, setApplicationTitle,selectAction } from "../panelSlice";
import MaterialUiButton from "../../../components/Button/MaterialUiButton";

export function EventList(props) {
  const { dispatch } = props;
  const [state, setState] = useState({ selectedEvent: "" });
  const availableActions = useSelector(selectUserAvailableActions) || {};
  const action = useSelector(selectAction)

  useEffect(()=>{

    // Clears the Application title upon visit of this page
    dispatch(setApplicationTitle("LogPro Cloud"))
    
    // This is here to set the default value of the selector
    if(action !== null && state.selectedEvent?.length === 0) {
      setState((prevState=>({...prevState,selectedEvent:action.label})))
    } 
  }, [action, dispatch, state.selectedEvent?.length])

  return (
    <Box
      sx={{
        alignItems: "center",
        minWidth: "90vw",
        padding: "10px",
      }}
    >
      <Grid container>
        {/* Header */}
        <Grid item xs={12}>
          <Typography variant="h5" sx={{ color: "blue" }}>
            Event List
          </Typography>
        </Grid>

        {/* Event List */}
        <Grid item xs={12}>
          <Box mt={1}>
            <Select
              fullWidth
              onChange={(event) => {
                setState((prevState) => ({
                  ...prevState,
                  selectedEvent: event.target.value,
                }));
              }}
              value={state.selectedEvent}
            >
              <MenuItem defaultValue disabled>
                Select Event
              </MenuItem>
              {Object.keys(availableActions).map((item, index) => (
                <MenuItem key={`${item}-${index}`} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Grid>

        {/* Submit Button */}
        <Grid item xs={12}>
          <Box mt={2}>

            {/* We currently have to do this funky ass stank hack until we can mature
            the forms endpoint */}
            <Link to={state.selectedEvent === "Receive" ? "/receive" : "/forms"} style={{textDecoration:'none'}}>
              <MaterialUiButton
                fullWidth
                disabled={state.selectedEvent?.length === 0}
                label="Submit"
                onClick={() => {
                  // Set the application title
                  dispatch(setApplicationTitle(state.selectedEvent))
                  
                  // Asign the selected Action
                  dispatch(assignAction(availableActions[state.selectedEvent]))
                }}
                size="large"
              />
            </Link>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
