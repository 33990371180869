import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Container,
  CssBaseline,
  FormControlLabel,
  Grid,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { EncryptStorage } from "encrypt-storage";
import { selectHasAuth, assignAuth, selectToken } from "./authSlice";
import { useAuthQuery } from "../../services/atlas/atlasApi";
import { useSelector, useDispatch } from "react-redux";
import locatorxdark from "../../assets/locatorxdark.png";
import styled from "styled-components";
import Copyright from "./Copyright";
import ForgotPassword from "./ForgotPassword";
import { LoginUser } from "../../utils/api";
import MaterialSnackbar from "../../components/MaterialSnackbar";

const theme = createTheme();

const Image = styled.img`
  width: 70%;
`;

export const encryptStorage = new EncryptStorage("smickThogExcess", {
  prefix: "@pwa",
  storageType: "localStorage",
});

export function Login(props) {
  const [state, setState] = useState({
    isForgotPassword: false,
    showSnackbar: false,
    snackbarText: "",
  });
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [skip, setSkip] = useState(true);
  const dispatch = useDispatch();
  const emailDefault = encryptStorage.getItem("email");

  //
  // skip allows us to not fetch until the data is ready
  //
  useAuthQuery({ email: email, password: password }, { skip });

  function handleSubmit(event) {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const email = formData.get("email");
    const password = formData.get("password");

    if (email && password && email.length > 1 && password.length > 1) {
      // good to hit the auth endpoint
      //
      setEmail(email);
      setPassword(password);
      LoginUser({ email, password }).then((res) => {
        if (res.success) {
          encryptStorage.setItem("email", email);
          encryptStorage.setItem("password", password);
          localStorage.setItem("token", res.token);
          dispatch(assignAuth(true));
        } else {
          setState((prevState) => ({
            ...prevState,
            showSnackbar: true,
            snackbarText: res.error,
          }));
        }
      });
      // setSkip(false);
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <MaterialSnackbar
        open={state.showSnackbar}
        handleClose={() =>
          setState((prevState) => ({ ...prevState, showSnackbar: false }))
        }
        severity="error"
        text={state.snackbarText}
      />
      <CssBaseline />
      <Container component="main" maxWidth="xs">
        {/* Logo */}
        <Grid item xs={12}>
          <Box alignItems="center" display="flex" flexDirection="column" mt={5}>
            <Image src={locatorxdark} alt="company-logo" />
          </Box>
        </Grid>

        {/* Sign In */}
        <Grid item xs={12}>
          <Box mt={1} textAlign="center">
            {!state.isForgotPassword ? (
              <Typography component="h1" variant="h5">
                Sign in
              </Typography>
            ) : (
              <Typography component="h1" variant="h5">
                Forgot Password
              </Typography>
            )}
          </Box>
        </Grid>

        {/* Sign In Form / Forgot Password */}
        {state.isForgotPassword ? (
          <ForgotPassword {...props} setState={setState} />
        ) : (
          <>
            {/* Email / Address/ Sign In */}
            <Grid item xs={12}>
              <Box
                component="form"
                onSubmit={(e) => {
                  handleSubmit(e);
                }}
                noValidate
                mt={1}
              >
                {/* Email */}
                <TextField
                  autoComplete="email"
                  autoFocus
                  defaultValue={emailDefault ? emailDefault : null}
                  fullWidth
                  id="email"
                  label="Email Address"
                  margin="normal"
                  name="email"
                  required
                />

                {/* Password */}
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                />

                {/* Remember Me Checkbox */}
                <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label="Remember me"
                />

                {/* Sign In Button */}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Sign In
                </Button>
              </Box>
            </Grid>

            {/* Forgot Password / Sign Up */}
            <Grid item xs={12}>
              <Box display="flex" justifyContent="space-between">
                <Link
                  href="#"
                  variant="body2"
                  onClick={() =>
                    setState((prevState) => ({
                      ...prevState,
                      isForgotPassword: true,
                    }))
                  }
                >
                  Forgot password?
                </Link>
              </Box>
            </Grid>
          </>
        )}

        {/* Copyright */}
        <Grid item xs={12}>
          <Box mt={2} mb={4}>
            <Copyright />
          </Box>
        </Grid>
      </Container>
    </ThemeProvider>
  );
}
