import React, { useState } from "react";
import { Button } from "@mui/material";
import { openCamera, openCameraWithResult } from "./cameraSlice";
import { useDispatch } from "react-redux";
import BarcodeScannerComponent from "react-qr-barcode-scanner";

export default function CameraScan() {
  const [data, setData] = useState("Not Found");
  const dispatch = useDispatch();

  function handleClick(event) {
    event.preventDefault();
    dispatch(openCamera(false));
  }

  return (
    <div>
      <BarcodeScannerComponent
        width={500}
        height={500}
        onUpdate={(err, result) => {
          if (result) {
            setData(result.text);
            dispatch(openCameraWithResult(result.text));
          } else setData("Not Found");
        }}
      />
      <p>{data}</p>
      <Button
        fullWidth
        onClick={(e) => {
          handleClick(e);
        }}
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
      >
        Close Camera
      </Button>
    </div>
  );
}
