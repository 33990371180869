import { Button } from "@mui/material";
export default function MaterialUiButton(props) {
  const {
    color = "primary",
    classes = {},
    disabled = false,
    fullWidth = false,
    label = "Click Here",
    onClick = ()=>{},
    size = "small",
    sx = {},
    variant = "contained",
  } = props;

  return (
    <Button
      color={color}
      classes={classes}
      disabled={disabled}
      fullWidth={fullWidth}
      onClick={onClick}
      size={size}
      sx={sx}
      variant={variant}
    >
      {label}
    </Button>
  );
}
