import * as React from "react";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { openCamera, openCameraWithResult } from "../scanner/cameraSlice";
import { selectFormFields } from "./panelSlice";
import { selectHasCamera } from "../appSettings/appSettingsSlice";
import { useSelector, useDispatch } from "react-redux";

export function FormFieldBarcode(props) {
  const dispatch = useDispatch();
  const { fieldIndex, handleFieldValue, label, buttonLabel, allowMultiple } =
    props;
  const hasCamera = useSelector(selectHasCamera);
  const formFields = useSelector(selectFormFields);

  function handleCameraResult(raw) {
    dispatch(handleFieldValue(fieldIndex, raw));
    dispatch(openCamera(false));
  }

  function handleClick(event) {
    event.preventDefault();
    dispatch(openCameraWithResult(handleCameraResult));
  }

  function handleSubmit(event) {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const barcode = formData.get("barcode");

    if (barcode && barcode.length > 1) {
      handleFieldValue(fieldIndex, barcode, allowMultiple);
    }
  }

  function CameraBits(props) {
    if (props.hasCamera) {
      return (
        <Button
          onClick={(e) => {
            handleClick(e);
          }}
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          {buttonLabel}
        </Button>
      );
    }
    return <div />;
  }

  const valueComps = [];
  if (formFields && formFields[fieldIndex]) {
    if (formFields[fieldIndex].fieldValues) {
      formFields[fieldIndex].fieldValues.forEach((v) => {
        valueComps.push(<Typography>{v}</Typography>);
      });
    } else if (formFields[fieldIndex].fieldValue) {
      valueComps.push(
        <Typography>{formFields[fieldIndex].fieldValue}</Typography>
      );
    }
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography
          fontSize="14px"
          align="left"
          variant="subtitle1"
          component="div"
        >
          {label}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Box
          component="form"
          onSubmit={(e) => {
            handleSubmit(e);
          }}
          noValidate
          sx={{ pb: 2 }}
        >
          <Grid container spacing={2}>
            <Grid item xs={9}>
              <TextField
                id="outlined-basic"
                label="10-digit code"
                fullWidth
                name="barcode"
              />
            </Grid>
            <Grid item xs={3}>
              <Button fullWidth type="submit" variant="contained" name="stupid">
                Apply
              </Button>
            </Grid>
          </Grid>
          <CameraBits hasCamera={hasCamera} />
          {valueComps}
        </Box>
      </Grid>
    </Grid>
  );
}
