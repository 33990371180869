import { useState } from "react";
import { Box, Button, Icon, Grid, Link, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import forgotPasswordRequest from "./api";

export default function ForgotPassword(props) {
  const { apiUrl, setState } = props;
  const [forgotState, setForgotState] = useState({
    email: "",
    isEmailValid: true,
    showSuccessMessage: false,
  });

  function validateEmail(event) {
    event.preventDefault();
    // Ripped from: https://bobbyhadz.com/blog/react-check-if-email-is-valid
    // Quick test to see if the email is valid. Returns a boolean
    const isValidEmail = /\S+@\S+\.\S+/.test(forgotState.email);

    if (isValidEmail) {
      forgotPasswordRequest({ apiUrl, email: forgotState.email }).then(
        (res) => {
          if (res?.success) {
            setForgotState(prevState=>({...prevState,showSuccessMessage:true}))
          }
        }
      );
    } else {
      setForgotState((prevState) => ({ ...prevState, isEmailValid: false }));
    }
  }

  return (
    <Box
      component="form"
      onSubmit={(event) => {
        validateEmail(event);
      }}
      noValidate
      mt={1}
    >
      <Icon />
      <Grid container>
        {/* Success Message / Email */}
        {forgotState.showSuccessMessage ? (
          <>
            <Grid item xs={12}>
              <Box mb={2} textAlign="center" sx={{ color: "#A0C46D" }}>
                <i className="far fa-check-circle fa-5x" />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box fontWeight="bold" mt={1} textAlign="center">
                <Typography variant="h4">
                  Check your email for the Forgot Password Request email.
                </Typography>
                <Box mt={2}>
                  <Typography variant="h6">
                    Some email servers flag this email as spam or junk. If you
                    don't receive the email in your inbox within 5 minutes,
                    check your Spam or Junk email folders.
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12}>
              {/* Forgot Email */}
              <TextField
                autoComplete="email"
                autoFocus
                error={!forgotState.isEmailValid}
                fullWidth
                helperText={
                  forgotState.isEmailValid ? null : (
                    <span style={{ color: "#B71D1A" }}>Invalid Email</span>
                  )
                }
                id="email"
                label="Email Address"
                margin="normal"
                name="email"
                onChange={(event) =>
                  setForgotState((prevState) => ({
                    ...prevState,
                    email: event.target.value,
                    isEmailValid: true,
                  }))
                }
                required
                value={forgotState.email}
              />
            </Grid>
            {/* Submit Button */}
            <Grid item xs={12}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Submit
              </Button>
            </Grid>
          </>
        )}

        {/* Go Back */}
        <Grid item xs={12}>
          <Box mt={2}>
            <Link
              href="#"
              variant="body2"
              onClick={() =>
                setState((prevState) => ({
                  ...prevState,
                  isForgotPassword: false,
                }))
              }
            >
              Go Back
            </Link>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
