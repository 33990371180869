import { atlasApi } from "../../services/atlas/atlasApi";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  hasCamera: null,
  hasSettings: false,
  settings: {},
};

export const appSettingsSlice = createSlice({
  name: "appSettings",
  initialState,
  reducers: {
    assignSettings: (state, data) => {
      state.settings = data.payload;
      state.hasSettings = true;
    },
    assignHasCamera: (state, hasCamera) => {
      state.hasCamera = hasCamera.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      atlasApi.endpoints.appSettings.matchFulfilled,
      (state, { payload }) => {
        state.settings = payload;
        state.hasSettings = true;
      }
    );
  },
});

export const selectHasSettings = (state) => state.appSettings.hasSettings;
export const selectHasCamera = (state) => state.appSettings.hasCamera;

export const { assignSettings, assignHasCamera } = appSettingsSlice.actions;

export default appSettingsSlice.reducer;
