import { useEffect, useState } from "react";
import {
  selectAction,
  selectAsset,
  selectForm,
  selectLocation,
  setForm,
} from "../panelSlice";
import { Box, Button, Grid } from "@mui/material";
import { addAssemblyToAnAsset, submitEditAsset } from "../../../utils/api";
import { FormFieldAssets } from "../FormFieldAssets";
import { FormFieldBarcode } from "../FormFieldBarcode";
import { FormFieldQAAssets } from "../FormFieldQAAssets";
import { FormFieldSelect } from "../../../components/FormFields/FormFieldSelect";
import { FormFieldText } from "../../../components/FormFields/FormFieldText";
import { Navigate } from "react-router-dom";
import { selectForms } from "../../auth/authSlice";
import { useGeolocated } from "react-geolocated";
import { useSelector, useDispatch } from "react-redux";
import AssetIdTextField from "../../../components/FormFields/AssetIdTextField/AssetIdTextField";
import MaterialSnackbar from "../../../components/MaterialSnackbar";
import ScannedAssetTextField from "../../../components/FormFields/ScannedAssetTextField/ScannedAssetTextField";

export function FormPanel(props) {
  const { token } = props;
  const [body, setBody] = useState({});
  const [formFields, setFormFields] = useState({});
  const [state, setState] = useState({
    applyActiveTagChecked: false,
    externalId: null,
    navigateToEventListPage: false,
    snackbarShow: false,
    snackbarText: "Success",
    snackbarSeverity: "success",
  });
  const [assetInfo, setAssetInfo] = useState({});
  const {
    externalId,
    navigateToEventListPage,
    snackbarShow,
    snackbarText,
    snackbarSeverity,
  } = state;
  const action = useSelector(selectAction);
  const asset = useSelector(selectAsset);
  const binLocation = useSelector(selectLocation);
  const classificationMap = asset ? asset.classificationMap : null;
  const dispatch = useDispatch();
  const form = useSelector(selectForm);
  const forms = useSelector(selectForms);
  const { coords, getPosition, isGeolocationAvailable, isGeolocationEnabled } =
    useGeolocated({
      positionOptions: { enableHighAccuracy: false },
      userDecisionTimeout: 5000,
    });

  // Adds the location
  if (isGeolocationAvailable && isGeolocationEnabled && coords) {
    body.latitude = coords.latitude;
    body.longitude = coords.longitude;
  }

  function handleFieldValue(fieldIndex, fieldValue, allowMultiple) {
    console.log("hi", fieldValue, fieldIndex);
    setFormFields((prevState) => {
      prevState[fieldIndex].value = fieldValue;

      return prevState;
    });
  }

  const fieldComps = Object.keys(formFields).map((formField, index) => {
    const specificField = formFields[formField];
    const { fieldType, fieldKey, fieldValue, formOptions, label, readOnly } =
      specificField;

    // Here we check to see if the field is a text field AND an assetId field.
    // We have to do special steps with these fields, so they broken out of the switch
    // statement
    if (fieldType === "text" && fieldKey === "assetId") {
      return (
        <AssetIdTextField
          assetInfo={assetInfo}
          key={index}
          fieldIndex={index}
          fieldKey={fieldKey}
          formFields={formFields}
          label={label}
          onChange={(e) => {
            // We follow a seperate flow for forms with assemblyIds in the onSubmit
            if (fieldKey === "assemblyId") {
              setState((prevState) => ({
                ...prevState,
                externalId: e.target.value,
              }));
            }
            setFormFields((prevState) => ({
              ...prevState,
              [formField]: {
                ...prevState[formField],
                fieldValue: e.target.value,
              },
            }));
          }}
          onClear={() => {
            // Clear out the assetInfo
            setAssetInfo({});

            // Clear out any value this might of had
            setFormFields((prevState) => ({
              ...prevState,
              [formField]: { ...prevState[formField], fieldValue: "" },
            }));
          }}
          readOnly={readOnly}
          setAssetInfo={setAssetInfo}
          token={token}
          value={formFields[index].fieldValue}
        />
      );
    }

    switch (fieldType) {
      case "externalAssembly":
        return (
          <FormFieldText
            key={index}
            fieldIndex={index}
            label={label}
            onChange={(e) => {
              // We follow a seperate flow for forms with assemblyIds in the onSubmit
              if (fieldKey === "assemblyId") {
                setState((prevState) => ({
                  ...prevState,
                  externalId: e.target.value,
                }));
              }

              setFormFields((prevState) => ({
                ...prevState,
                [formField]: {
                  ...prevState[formField],
                  fieldValue: e.target.value,
                },
              }));
            }}
            onClear={() => {
              setFormFields((prevState) => ({
                ...prevState,
                [formField]: { ...prevState[formField], fieldValue: "" },
              }));
            }}
            readOnly={readOnly}
            value={formFields[index].fieldValue}
          />
        );
      case "text":
        return (
          <FormFieldText
            key={index}
            fieldIndex={index}
            label={label}
            onChange={(e) => {
              // We follow a seperate flow for forms with assemblyIds in the onSubmit
              if (fieldKey === "assemblyId") {
                setState((prevState) => ({
                  ...prevState,
                  externalId: e.target.value,
                }));
              }

              setFormFields((prevState) => ({
                ...prevState,
                [formField]: {
                  ...prevState[formField],
                  fieldValue: e.target.value,
                },
              }));
            }}
            onClear={() => {
              setFormFields((prevState) => ({
                ...prevState,
                [formField]: { ...prevState[formField], fieldValue: "" },
              }));
            }}
            readOnly={readOnly}
            value={formFields[index].fieldValue}
          />
        );
      case "select":
        return (
          <FormFieldSelect
            defaultValue={fieldValue}
            fieldIndex={index}
            key={`${index} - ${fieldType}`}
            label={label}
            onChange={(e) => {
              setFormFields((prevState) => ({
                ...prevState,
                [formField]: {
                  ...prevState[formField],
                  fieldValue: e.target.value,
                },
              }));
            }}
            options={formOptions}
            readOnly={readOnly}
          />
        );
      case "number":
        return (
          <FormFieldText
            fieldIndex={index}
            key={index}
            label={label}
            multiline={false}
            onChange={(e) => {
              setFormFields((prevState) => ({
                ...prevState,
                [formField]: {
                  ...prevState[formField],
                  fieldValue: parseInt(e.target.value),
                },
              }));
            }}
            onClear={() => {
              setFormFields((prevState) => ({
                ...prevState,
                [formField]: { ...prevState[formField], fieldValue: "" },
              }));
            }}
            readOnly={readOnly}
            type="number"
            value={formFields[index].fieldValue}
          />
        );

      // Note: allowMultiple can be null, true or false
      //   null: a single value will be written into the fieldValue
      //   true: multiple values may be added to the fieldValues array
      //   false: a single value is maintained in the fieldValues array
      //
      case "scannedAssets":
        return (
          <ScannedAssetTextField
            assetInfo={assetInfo}
            key={index}
            fieldIndex={index}
            fieldKey={fieldKey}
            formFields={formFields}
            label={label}
            onChange={(event) => {
              setFormFields((prevState) => ({
                ...prevState,
                [formField]: {
                  ...prevState[formField],
                  fieldValue: event.target.value,
                },
              }));
            }}
            onClear={() => {
              // Clear out the assetInfo
              setAssetInfo({});

              // Clear out any value this might of had
              setFormFields((prevState) => ({
                ...prevState,
                [formField]: { ...prevState[formField], fieldValue: "" },
              }));
            }}
            readOnly={readOnly}
            setAssetInfo={setAssetInfo}
            token={token}
            value={formFields[index].fieldValue}
          />
        );

      case "associate":
        break;
      case "dissociate":
        break;
      case "externalId":
        return (
          <FormFieldAssets
            allowMultiple={false}
            buttonLabel="Scan Code"
            fieldIndex={index}
            handleFieldValue={handleFieldValue}
            key={index}
            label="Scan a Barcode using the WebCam"
          />
        );
      case "scanQA":
        return (
          <FormFieldQAAssets
            allowMultiple={false}
            binLocation={binLocation}
            buttonLabel="Scan Code"
            fieldIndex={index}
            handleFieldValue={handleFieldValue}
            key={index}
            label="Scan a Barcode using the WebCam"
          />
        );
      case "location":
        return (
          <FormFieldText
            key={index}
            fieldIndex={index}
            label={label}
            onChange={(e) => {
              // We follow a seperate flow for forms with assemblyIds in the onSubmit
              if (fieldKey === "assemblyId") {
                setState((prevState) => ({
                  ...prevState,
                  externalId: e.target.value,
                }));
              }

              setFormFields((prevState) => ({
                ...prevState,
                [formField]: {
                  ...prevState[formField],
                  fieldValue: e.target.value,
                },
              }));
            }}
            onClear={() => {
              setFormFields((prevState) => ({
                ...prevState,
                [formField]: { ...prevState[formField], fieldValue: "" },
              }));
            }}
            readOnly={readOnly}
            value={formFields[index].fieldValue}
          />
        );
      case "externalSku":
        return (
          <FormFieldBarcode
            buttonLabel="Scan Code"
            fieldIndex={index}
            handleFieldValue={handleFieldValue}
            key={index}
            label="Scan a SKU Barcode using the WebCam"
          />
        );
      default:
        console.log("***** can't process field type: " + formField.fieldType);
        break;
    }
    return null;
  });

  function handleSnackBar({ severity = "success", text = "Success" }) {
    // We need to throw the success modal up. Also need to null the externalId out
    setState((prevState) => ({
      ...prevState,
      snackbarSeverity: severity,
      snackbarShow: true,
      snackbarText: text,
    }));

    // We need to take the success modal down
    setTimeout(() => {
      setState((prevState) => ({
        ...prevState,
        snackbarShow: false,
        snackbarText: "Success",
        snackbarSeverity: "success",
      }));
    }, 1000);
  }

  function editAsset() {
    submitEditAsset({
      action,
      formFields,
      token,
    }).then((res) => {
      // Until we get the costpoint servers, this is going to default to true. Otherwise it should
      // go to res.success
      if (true) {
        // We need to clear all the formFieldValues
        setFormFields((prevState) => {
          let newState = { ...prevState };
          Object.keys(prevState).forEach((item) => {
            const specificField = newState[item];

            specificField.fieldValue = "";
          });

          return newState;
        });

        // Also need to clear out the assetInfo
        setAssetInfo({});

        // We need to throw the success modal up. Also need to null the externalId out
        setState((prevState) => ({ ...prevState, externalId: null }));
        handleSnackBar();
      }
    });
  }

  // The useEffect grabs the selected forms fieldList and throws it into state
  useEffect(() => {
    if (action && forms && !form) {
      const formId = action.formId;

      // Grabbing the selected form
      const formTemp = forms.find((f) => f.formId === formId);

      // This statement sets up the formFields
      if (formTemp) {
        dispatch(setForm(formTemp));

        // Does the form have fields?
        if (formTemp.fields) {
          const collectionOfFields = {};

          formTemp.fields.forEach((field, index) => {
            const fieldCopy = { ...field };
            // We first check to see if the item is comign from a classification
            if (
              fieldCopy.fieldKey === "classificationMap" &&
              fieldCopy.topLevel &&
              classificationMap
            ) {
              const associatedClassification =
                asset.classificationMap[fieldCopy.topLevel];
              if (associatedClassification) {
                const fo = field.formOptions.find((fo) => {
                  return fo.label === associatedClassification;
                });

                if (fo) {
                  fieldCopy.fieldValue = fo.formOptionId;
                }
              }
            }

            // Setting the default value
            fieldCopy["fieldValue"] = "";

            collectionOfFields[index] = fieldCopy;
          });
          setFormFields(collectionOfFields);
        }
      }
    }
  }, [dispatch, asset, classificationMap, form, formFields, action, forms]);

  return (
    <Box
      component="div"
      sx={{
        alignItems: "center",
        padding: "10px",
      }}
    >
      {/* If no event is present, we are forcing them back to the eventList 
      page to avoid any accidental scans with the wrong event */}
      {action ? null : <Navigate to={"/eventList"} />}

      {/* Something weird was going on with adding this to the ternary above so i broke it out
      to its own line - Handles when the user clicks the  "Go back to event list" button */}
      {navigateToEventListPage ? <Navigate to={"/eventList"} /> : null}

      {/* Success Message Snackbar */}
      <MaterialSnackbar
        open={snackbarShow}
        handleClose={() =>
          setState((props) => ({ ...props, snackbarShow: false }))
        }
        text={snackbarText}
        severity={snackbarSeverity}
      />
      <Grid container>
        {/* Form Fields */}
        <Grid item xs={12}>
          {fieldComps ? fieldComps : null}
        </Grid>

        {/* Buttons Container */}
        <Box
          backgroundColor="white"
          bottom="0"
          display="flex"
          justifyContent="space-between"
          position="sticky"
          width="100%"
        >
          {/* Submit Button */}
          <Grid item xs={4}>
            <Button
              // Has any assetInfo been appended yet?
              disabled={assetInfo.success === undefined ? true : false}
              fullWidth
              onClick={(e) => {
                editAsset();
              }}
              sx={{ mt: 3, mb: 2 }}
              variant="contained"
            >
              Submit
            </Button>
          </Grid>

          {/* Clear Form Button */}
          <Grid item xs={4}>
            <Button
              fullWidth
              onClick={() => {
                setState({
                  applyActiveTagChecked: false,
                  externalId: null,
                  navigateToEventListPage: false,
                  snackbarShow: false,
                });

                // We need to clear all the formFieldValues
                setFormFields((prevState) => {
                  let newState = { ...prevState };
                  Object.keys(prevState).forEach((item) => {
                    const specificField = newState[item];

                    specificField.fieldValue = "";
                  });

                  return newState;
                });

                // Also need to clear out the assetInfo
                setAssetInfo({});
              }}
              sx={{ mt: 3, mb: 2 }}
              variant="contained"
            >
              CLEAR
            </Button>
          </Grid>
        </Box>
      </Grid>
    </Box>
  );
}
